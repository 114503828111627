import React from "react"
import SEO from "../components/seo"
import Layout from "../components/layout"
import mentors from "../json/mentors.json"
import MentorCard from "../components/mentorspage/MentorCard"
import Div8095 from "../components/utils/Div8095"
import styled from "styled-components"
import ContainerDiv from "../components/utils/ContainerDiv"
import HeadingCard from "../components/utils/HeadingCard"

const StyledFlexBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
`

const Mentors = () => {
  const mentorList = mentors.map((e, i) => {
    return (
      <MentorCard key={i} fullname={e.fullname} slug={e.slug} image={e.image} />
    )
  })
  return (
    <Layout>
      <SEO title={"Mentors"} />
      <ContainerDiv bg="#9abbcc">
        <Div8095>
          <HeadingCard>Get to Know our Dedicated Mentors</HeadingCard>
          <StyledFlexBox>{mentorList}</StyledFlexBox>
        </Div8095>
      </ContainerDiv>
    </Layout>
  )
}

export default Mentors
